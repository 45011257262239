@import 'shared/styles/global';

$header-height: 120px;

.container {
	overflow: hidden;
}

.info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 2, 2, 3);

	&Buttons {
		display: flex;
		gap: spacing(1);
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 10;

	background-color: white;
}

.list {
	height: calc(100vh - $header-height);
	padding-bottom: spacing(2);

	&Control {
		display: flex;
		justify-content: space-between;
		margin-bottom: 6px;
		padding: spacing(0, 2);

		&Reveal {
			display: flex;
			align-items: center;
			gap: spacing(1);
		}

		&Inline {
			display: flex;
			align-items: center;

			gap: spacing(2.5);
		}
	}
}

.project {
	padding: spacing(1,1,1,3);
}

.tableHeader {
	border-bottom: 1px solid #D9D9D9;
}

.tableColumn {
	background: none;
}
