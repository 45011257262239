@import 'shared/styles/global';

.wrapper {
	padding-bottom: spacing(6);
	overflow-y: scroll;
}

.title {
	padding: 11px 16px;
	border-bottom: solid 1px $c-lighter-grey;
}

.open {
	margin: spacing(0, 2, 3, 2);

	font-weight: 500;
}

.programming {
	border-bottom: solid 1px $c-lighter-grey;
}

.section {
	display: flex;
	gap: spacing(2);
	padding: spacing(3, 2);

	&Skills {
		flex-direction: column;
	}

	&Programming {
		flex-wrap: wrap;
		padding: spacing(3, 2, 2, 2);
	}
}

.skill{
	&Info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		font-weight: 500;
	}
}

.progress{
	height: inherit;
	border-radius: 1px;

	background: $c-success;

	&Bar {
		height: 10px;
		border-radius: 1px;

		background: $c-lighter-grey;
	}
}

.row {
	display: flex;

	&:not(:last-child) {
		margin-bottom: spacing(2);
	}

	&Name {
		display: inline-block;
		align-self: center;
		width: 145px;
		margin-right: spacing(2);

		overflow-wrap: break-word;
	}

	&Matched {
		display: flex;
		align-items: center;
		margin-bottom: spacing(1);
	}

	&Centered {
		align-items: center;
	}
}

.company {
	padding: spacing(2);
	border-bottom: solid 1px $c-lighter-grey;

	&Title {
		display: flex;
		align-items: center;
		gap: spacing(1);

		margin-bottom: spacing(2);
	}
}

.icon {
	&Linkedin {
		height: 20px;
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Website {
		height: 20px;
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}
}

.more {
	padding: spacing(1, 2);
}
