@import "shared/styles/global";

.card {
	margin-top: 0;

	opacity: 0;

    width: 500px;
	padding: spacing(4) 100px spacing(8);

	&Visible {
		opacity: 1;

		transition: $transition;
	}

    &Perks {
		display: flex;
		flex-direction: column;

        ul {
            list-style: none;
    
            li {
                display: flex;
                gap: spacing(1);
                align-items: center;
    
                img {
                    width: 20px;
                    height: 20px;
                }
    
                &:not(:last-child) {
                    margin-bottom: spacing(2);
                }
    
                span {
                    font-weight: 700;
                }
            }
        }
	}
}

.success {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;

	&Icon {
		width: 180px;
		height: 180px;
	}
}

.description {
	text-align: center;
}

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	padding: spacing(3);

	background-color: $c-very-light-grey;

	&Row {
		display: flex;
		justify-content: space-between;

		&Wrapper {
			display: flex;
			flex-direction: column;
			gap: spacing(1);
		}
	}

	&Interval {
		margin: spacing(1, 0, 2);
	}
}

.capitalize {
	text-transform: capitalize;
}

.contact {
	color: rgba($c-link-blue, .78);
}

.wrapper {
    display: flex;
    justify-content: flex-end;
}
