@import "shared/styles/global";

.success {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
}

.wrapper {
    display: flex;
    justify-content: flex-end;
    gap: spacing(2);
}

.highlight {
    margin-top: spacing(-2);

    span {
        font-weight: bold;
    }
}
