@import "shared/styles/global";

.card {
	margin-top: 0;

	opacity: 0;

    width: 500px;
	padding: spacing(4) 100px spacing(8);

	&Visible {
		opacity: 1;

		transition: $transition;
	}

    &Perks {
		display: flex;
		flex-direction: column;

        ul {
            li {
                margin-left: spacing(1);
    
                &:not(:last-child) {
                    margin-top: spacing(1);
                    margin-bottom: spacing(0.5);
                }
            }
        }
	}
}

.success {
	display: flex;
	flex-direction: row;
    justify-content: space-between;
	align-items: center;

    &Column {
        display: flex;
        flex-direction: column;
        gap: spacing(3);
    }

	&Icon {
		width: 110px;
		height: 110px;
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	padding: spacing(3);

	background-color: $c-very-light-grey;

	&Row {
		display: flex;
		justify-content: space-between;

		&Wrapper {
			display: flex;
			flex-direction: column;
			gap: spacing(1);
		}
	}

	&Interval {
		margin: spacing(1, 0, 2);
	}
}

.capitalize {
	text-transform: capitalize;
}

.contact {
	color: rgba($c-link-blue, .78);
}

.wrapper {
    display: flex;
    justify-content: center;
    gap: spacing(1.5);

    &Icon {
        cursor: pointer;
    }
}

.primary {
	padding: spacing(1.5, 4);
	background: linear-gradient(94.98deg, #4ee036 -26.18%, #89d5ff 126.51%);

	cursor: pointer;

	&:hover {
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(94.98deg, #4ee036 -26.18%, #89d5ff 126.51%);
	}
}
