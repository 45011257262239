@import 'shared/styles/global';

.wrapper {
	padding-bottom: spacing(6);
	overflow-y: scroll;
}

.title {
	padding: 11px 16px;
	border-bottom: solid 1px $c-lighter-grey;
}

.open {
	margin: spacing(0, 2, 3, 2);

	font-weight: 500;
}

.programming {
	border-bottom: solid 1px $c-lighter-grey;
}

.section {
	display: flex;
	gap: spacing(1);
	padding: spacing(3, 2);

	&Skills {
		flex-direction: column;
	}

	&Programming {
		flex-wrap: wrap;
		padding: spacing(3, 2, 2, 2);
	}
}

.skill{
	&Info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		font-weight: 500;
	}
}

.progress{
	height: inherit;
	border-radius: 1px;

	background: $c-success;

	&Bar {
		height: 10px;
		border-radius: 1px;

		background: $c-lighter-grey;
	}
}

.tag {
	padding: spacing(0.5, 1);

	&Intent {
		background: $c-background-green;
	}

	&Tech {
		background: #E5E0FF;
	}
}
