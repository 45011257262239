@import 'shared/styles/global';

.section {
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 60px;
}

.back {
	position: absolute;
	top: -7px;
	left: 20px;

	padding: spacing(1, 3, 1, 1);

	&Icon {
		transform: rotate(90deg);
	}
}

.goToSearch {
	position: absolute;
	top: -7px;
	right: 20px;
}

.title {
	text-align: center;
}

.about {
	display: flex;
	flex-direction: column;
	gap: spacing(5);
	align-items: center;
}

.subtitle {
	max-width: 630px;

	text-align: center;
}

.perks {
	display: flex;
	gap: spacing(3);
	justify-content: space-between;
}

.perk {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: spacing(2);
	align-items: center;

	span {
		font-weight: 700;
	}
}
