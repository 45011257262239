@import 'shared/styles/global';

.container {
	display: flex;
	flex-direction: column;
	gap: spacing(10);
	max-width: 1312px;
	margin: 0 auto;
	padding: 62px 16px 28px;
}
