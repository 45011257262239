@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

a {
	font-family: $f-inter;
	text-decoration: none;
	color: inherit;
}

.link{
	color: #4e91dd;
}

ul {
	list-style-type: none;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $f-inter;
	font-weight: 500;
	color: #222;
	font-size: 14px;
	max-width: 1180px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.pageTitle {
	font-size: 54px;
	max-width: 800px;
	line-height: 58px;
	text-align: center;
	font-weight: 700;
	padding: 60px 0 0;
	margin-top: 40px;
}

.content__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 800px;
	padding: 25px 0 100px;
}

.blockTitle {
	font-size: 28px;
	font-weight: 500;
	align-self: start;
	margin-top: 30px;
}

.blockText {
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	margin-top: 30px;
	align-self: flex-start;
}

.video__content {
	margin-top: 60px;
	position: relative;
	height: 400px;
    width: 100%;
}

.textList {
	list-style-type: decimal;
	margin: 10px 40px 0;
	align-self: start;
}

.listItem {
	font-size: 18px;
	font-weight: 300;
	margin: 15px 0;
}

.itemDescr {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.divider {
    width: 100%;
    display: flex;
	height: 2px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
}

@media (max-width: 900px) {
	.container {
		padding: 0 20px;
	}
}

@media (max-width: 640px) {
	.pageTitle{
		font-size: 42px;
		padding-top: 0;
	}

	.content__wrapper{
		padding-bottom: 70px;
	}
	
	.blockTitle{
		font-size: 32px;
		align-self: center;
		text-align: center;
	}

	.blockText{
		margin-top: 20px;
		text-align: center;
	}
}


@media (max-width: 440px){
	.pageTitle{
		font-size: 36px;
		margin-top: 20px;
	}

	.blockText{
		font-size: 16px;
	}
}
