@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

a {
	font-family: $f-inter;
	text-decoration: none;
	color: inherit;
}

.section {
	margin-bottom: 100px;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	font-family: $f-inter;
	font-weight: 500;
	color: #222;
	font-size: 14px;
	max-width: 1180px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.section__inner {
	margin-bottom: 100px;
}

.menu__sign-up {
	display: flex;
	height: 44px;
	padding: 12px 16px;
	line-height: 18px;
	background-color: #fbf9f7;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: color 0.2s ease, background-color 0.2s ease;
}

.menu__sign-up:hover {
	color: #222;
	background-color: #0097dd;
	background-image: linear-gradient(135deg, #0097dd, #00d85e);
}

.sign__up {
	border-radius: 8px;
	color: #fbf9f7;
	background-color: #222;
	transition: background-color 0.3s ease;
}

.sign__up:hover {
	color: #222;
	background-color: #0097dd;
	background-image: linear-gradient(135deg, #0097dd, #00d85e);
}

.absolute-block {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.landing-gradient {
	height: 60vh;
	bottom: auto;
	z-index: -1;
}

.pricing-gradient {
	height: 80vh;
	bottom: auto;
	z-index: -1;
}

.multi-color-gradient {
	background-image: linear-gradient(90deg, #fff0c7, #e5d4ff 38%, #c1e7bf 73%, #ffd7dd);
	z-index: -1;
}

.white-gradient-bottom {
	height: 40vh;
	background-image: linear-gradient(transparent, #fff);
	top: auto;
	z-index: -1;
}

.block {
	height: 3000px;
}

/* /////////// TOP SECTION /////////// */

.top__inner {
	display: flex;
	height: 90vh;
	text-align: center;
	flex-direction: column;
	margin-top: 1dvh;
}

.top__content {
	margin-top: auto;
	margin-bottom: auto;
}

.top__title {
	font-weight: 700;
	font-size: 98px;
	color: #222;
	line-height: 100px;
	margin-bottom: 7vh;
	text-align: center;
	z-index: 1;
}

.top__button {
	border-radius: 8px;
	font-size: 22px;
	padding: 18px 24px;
	line-height: 22px;
}

.top__sponsors-list {
	display: flex;
	gap: 30px;
	justify-content: center;
	padding: 0 0 2.5vh;
}

.sponsors__img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 100%;
	max-height: 60px;
}

/* /////////// ABOUT SECTION /////////// */

.about__inner {
	margin-top: 7vh;
	display: flex;
	flex-direction: column;
	text-align: center;
	max-width: 1060px;
}

.about__border {
	height: 2px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
}

.about__text {
	padding: 40px 0;
	font-weight: 400;
	font-size: 25px;
	line-height: 32px;
	word-spacing: 5px;
}

.about__text1 {
	font-weight: 600;
}

/* /////////// FEATURES SECTION /////////// */

.section__features {
	margin-top: 4vh;
}

.features__block-inner {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.features__block {
	display: flex;
	justify-content: center;
	gap: 30px;
}

.features__small-item {
	max-width: 420px;
	padding: 40px;
	background-color: #222;
	border-radius: 10px;
}

.features__back-purple {
	background-image: linear-gradient(225deg, #d554e9, #5499e9);
	opacity: 0.8;
}

.features__back-blue {
	background-image: linear-gradient(225deg, #0cf, rgb(9, 77, 122));
	opacity: 0.8;
}

.features__back-green {
	background-image: linear-gradient(135deg, #0097dd, #00d85e);
	opacity: 0.8;
}

.features__back-pastel {
	background-image: linear-gradient(225deg, #c2c8de59, #e5daee);
	opacity: 0.8;
}

.features__back-orange {
	background-image: linear-gradient(145deg, #f0c65c, #ef7b45);
}

.features__small-item .features__item-descr {
	color: #fbf9f7;
}

.features__big-item {
	padding: 40px;
	border-radius: 10px;
	width: 100%;
	max-width: 600px;
}

.features__big-item .features__item-title {
	color: #222;
}

.features__item-title {
	font-size: 32px;
	color: #fbf9f7;
}

.features__item-descr {
	margin-top: 12px;
	max-width: 400px;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
}

/* //////////  FEEDBACK SECTION  //////////// */

.section__feedback {
	.container {
		max-width: 1480px;
	}
}

.feedback__block {
	display: flex;
	justify-content: center;
	gap: 20px;
	font-family: $f-inter;
}

.section__title {
	margin-bottom: 70px;
	text-align: center;
	font-size: 52px;
	font-weight: 600;
	line-height: 60px;
}

.feedback__item-content {
	display: flex;
	gap: 20px;
	margin-bottom: 30px;
	max-width: 260px;
}

.feedback__item {
	width: 100%;
	flex-grow: 1;
	max-width: 280px;
	padding: 32px 20px;
	border: 1px solid #e5e4da;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

.feedback__item-banners {
	justify-content: space-between;
	align-items: center;
	max-width: 110px;
	flex-grow: 1;
	padding: 28px 18px;
	background-color: #f0eff8;
	border: 1px solid #e5e4da;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.medal {
	width: 64px;
	justify-self: center;
}

.feedback__item-logo {
	width: 48px;
	height: 48px;
	align-self: start;
	border-radius: 50%;
}

.feedback__item-descr {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.feedback__item-name {
	font-size: 18px;
	font-weight: 600;
}

.feedback__item-position {
	line-height: 26px;
}

.feedback__position-link {
	color: #5499e9;
}

@media (max-width: 1700px) {
	.top__button {
		font-size: 18px;
		padding: 16px 20px;
		line-height: 22px;
	}
}

@media (max-width: 1100px) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}

	.section__inner {
		margin-bottom: 80px;
	}

	.section__top {
		height: 100%;
	}

	.top__title {
		margin-left: auto;
		margin-right: auto;
		font-size: 64px;
		line-height: 68px;
		max-width: 700px;
	}

	.top__sponsors-list {
		display: grid;
		grid-template-columns: auto auto;
		gap: 80px;
		margin-bottom: 50px;
	}

	.feedback__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.feedback__block {
		display: grid;
		grid-template-columns: 302px 302px;
		gap: 20px;
	}

	.feedback__item,
	.feedback__item-banners {
		margin: 0 auto;
	}

	.feedback__item-banners {
		max-width: 300px;
		text-align: center;
		display: grid;
		grid-template-columns: 80px 80px;
		gap: 30px;

		grid-column: 1 / -1;
		justify-self: center;
		align-items: center;
	}
}

@media (max-width: 900px) {
	.section__title {
		font-size: 42px;
	}
}

@media (max-width: 768px) {
	.top__inner {
		height: 100%;
	}

	.top__title {
		margin-top: 20px;
		font-size: 48px;
		max-width: 500px;
	}

	.top__sponsors-list {
		margin-top: 70px;
	}

	.features__block {
		flex-direction: column;
		align-items: center;
	}

	.features__small-item {
		max-width: 480px;
	}

	.features__big-item {
		max-width: 480px;
	}
}

@media (max-width: 640px) {
	.header__inner {
		margin-bottom: 0;
	}

	.menu__list {
		display: none;
	}

	.top__content {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.top__title {
		font-size: 40px;
		margin-top: 0;
	}

	.section__title {
		font-size: 36px;
		margin-bottom: 40px;
		line-height: 40px;
		padding: 0 30px;
	}

	.top__sponsors-list {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 150px 150px;
		padding-top: 20px;
		gap: 30px;
		margin-bottom: 0;
	}

	.about__text {
		font-size: 22px;
	}

	.feedback__block {
		grid-template-columns: 302px;
	}
}

@media (max-width: 540px) {
	.top__title {
		font-size: 32px;
		line-height: 45px;
	}

	.section__title {
		font-size: 32px;
		padding: 0 20px;
	}

	.section__inner {
		margin-bottom: 50px;
	}

	.sponsors__img svg {
		width: 150px;
	}

	.sponsors__img {
		height: 40px;
	}

	.about__text {
		font-size: 20px;
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
	}

	.features__item-descr {
		line-height: 25px;
	}

	.features__item-title {
		font-size: 28px;
	}

	.features__small-item {
		padding: 22px;
	}

	.features__big-item {
		padding: 22px;
	}

	// .contact__fields {
	// 	flex-direction: column;
	// 	gap: 15px;
	// }

	// .contact__fields input {
	// 	padding: 10px;
	// 	text-align: center;
	// }

	// .iframe__block {
	// 	width: 390px;
	// 	height: 530px;
	// }

	// .footer__social {
	// 	gap: 40px;
	// 	margin-bottom: 30px;
	// }
}
