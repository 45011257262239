@import "shared/styles/global";

.logo {
	width: 32px;
	height: 32px;
	object-fit: contain;
}

.job {
	background: white;
}
