@import "shared/styles/global";

.footer {
	justify-content: space-between;

	&Logout {
		padding-left: spacing(1);

		&Icon {
			color: $c-dark-grey;
		}
	}
}
