@import 'shared/styles/global';

.skills {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(1);
	max-width: 40%;
}

.tag {
	padding: spacing(0.5, 1);
	border-radius: 1px;
}

.skill {
	background: #dbdbff;
}

.language {
	background: #adadff;
}
