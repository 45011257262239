@import 'shared/styles/global';

.header {
	font-family: $f-inter;
	font-weight: 500;
	color: #222;
	font-size: 16px;
	line-height: 18px;
}

.header__inner {
	display: flex;
	padding: 16px 30px;
	justify-content: space-between;
}

.menu__list {
	display: flex;
	gap: 15px;
	height: 100%;
}

.menu__listLink {
	color: #222;
	display: flex;
	height: 44px;
	padding: 12px 16px;
	line-height: 18px;
	background-color: #f3f1ef;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	z-index: 0;
	position: relative;
	transition: color 0.2s ease;
}

.menu__listLink::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.menu__listLink:hover {
	color: #fbf9f7;
	background-color: #d4b0f4;
}

.menu__listLink:hover::before {
	opacity: 1;
}

.menu__listButton {
	color: #222;
	display: flex;
	height: 44px;
	padding: 12px 16px;
	line-height: 18px;
	background-color: #f3f1ef;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	z-index: 0;
	position: relative;
	transition: color 0.2s ease;
	cursor: pointer;
}

.menu__listButton::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.menu__listButton:hover {
	color: #fbf9f7;
	background-color: #d4b0f4;
}

.menu__listButton:hover::before {
	opacity: 1;
}

.dropdownMenu {
	padding: 8px 0;
	top: 44px;
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 100%;
	z-index: 999;
	color: #222;
	line-height: 18px;
	background-color: transparent;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
}

.first {
	border-radius: 8px 8px 0 0;
}

.last {
	border-radius: 0 0 8px 8px;
}

.first:hover {
	background-color: #e6e6e4;
}

.last:hover {
	background-color: #e6e6e4;
}

.dropdownLink {
	width: 124px;
	display: flex;
	color: #222;
	height: 44px;
	padding: 16px 15px;
	line-height: 18px;
	background-color: #f3f1ef;
	justify-content: center;
	align-items: center;
}

.sign__up {
	border-radius: 8px;
	color: #fbf9f7;
	background-color: #222;
	transition: background-color 0.3s ease;
}

.sign__up:hover {
	color: #222;
	background-color: #0097dd;
	background-image: linear-gradient(135deg, #0097dd, #00d85e);
}

.logoWrapper {
	width: 182px;
	z-index: 0;
}

.logo {
	top: 20px;
	position: absolute;
	display: flex;
	align-items: center;
}

.menuLogo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropdownEnter {
	opacity: 0;
	transform: translateY(-10px);
}

.dropdownEnterActive {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 200ms, transform 200ms;
}

.dropdownExit {
	opacity: 1;
	transform: translateY(0);
}

.dropdownExitActive {
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 200ms, transform 200ms;
}

.dropdown {
	border-radius: 4px;
	overflow: hidden;
}

/////////////    mob menu    ///////////////

.mobHeader {
	display: none;
	background-color: #fff;
}

.mobBorder {
	display: none;
	height: 3px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
}

.burgerMenu {
	position: absolute;
	width: 26px;
	height: 26px;
	display: none;
	flex-direction: column;
	cursor: pointer;
	z-index: 20;
	right: 30px;
	top: 25px;
}

.mobList {
	display: none;
}

.mobButton {
	font-size: 22px;
	font-weight: 600;
	line-height: 24px;
	color: #000;
	display: flex;
	padding: 18px;
	background: linear-gradient(225deg, #e37ef3aa, #74aff3c2);
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	z-index: 0;
	margin: 0 7vh;
	margin-bottom: 15px;
	cursor: pointer;
}

.mobItem {
	font-size: 22px;
	font-weight: 600;
	line-height: 24px;
	color: #000;
	display: flex;
	padding: 18px;
	background: linear-gradient(225deg, #e37ef3aa, #74aff3c2);
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	z-index: 0;
	margin: 0 7vh;
	margin-bottom: 15px;
}

@media (max-width: 770px) {
	.header__inner {
		margin-bottom: 0;
		height: 70px;
	}

	.menu__signUp {
		display: none;
	}

	.menu__list {
		display: none;
	}

	.mobHeader {
		display: flex;
		padding: 16px 30px;
	}

	.burgerMenu {
		display: flex;
	}

	.burgerLine {
		width: 100%;
		height: 3px;
		background-color: #000;
		margin: 4px 0;
		transition: transform 0.3s ease-in-out;
		border-radius: 2px;
	}

	.burgerMenu.open {
		position: fixed;
	}

	.burgerMenu.open .burgerLine:nth-child(1) {
		transform: translateY(6px) rotate(45deg);
		background-color: #000;
	}

	.burgerMenu.open .burgerLine:nth-child(2) {
		transform: translateY(-5px) rotate(-45deg);
		background-color: #000;
	}

	.mobBorder {
		display: block;
	}

	.mobMenu {
		display: flex;
		flex-direction: column;
		gap: 12px;
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: #fff;
		opacity: 0;
		z-index: -1;
		transition: all 0.4s ease, z-index 0.6s;
	}

	.active {
		opacity: 1;
		z-index: 10;
	}

	.mobDropdownInner {
		display: flex;
		flex-direction: column;
		gap: 3px;
	}

	.mobDropdownMenu {
		opacity: 0;
		overflow: hidden;
		max-height: 0;
		transition: 0.3s ease-out, opacity 0.6s ease-out;
		display: flex;
		flex-direction: column;
	}

	.mobDropdownMenu.open {
		opacity: 1;
		max-height: 111px;
		margin-bottom: 15px;
	}

	.mobDropdownLink {
		font-size: 22px;
		font-weight: 600;
		color: #000;
		padding: 18px 0;
		line-height: 18px;
		background: #e6e6e4;
		justify-content: center;
		text-align: center;
		z-index: 0;
		margin: 0 7vh;
		border-radius: 8px;
		gap: 18px;
	}

	.mobDropdownLink:first-child {
		border-radius: 8px 8px 0 0;
	}

	.mobDropdownLink:last-child {
		border-radius: 0 0 8px 8px;
	}

	.mobList {
		display: flex;
		width: 100%;
		justify-content: center;
		flex-direction: column;
		margin-top: 15vh;
	}
}
