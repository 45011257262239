@import 'shared/styles/global';

.experience {
	display: flex;
	gap: spacing(1);

	&Wrapper {
		min-width: 0;
	}

	&Avatar {
		min-width: 60px;

		background: #dedede;
	}

	&Top {
		display: flex;
		gap: spacing(1);
		align-items: center;
		margin-bottom: spacing(1);
	}

	&Current {
		padding: 4px 8px;

		background: $c-very-light-green;
	}

	&Company {
		margin-bottom: spacing(1);

		cursor: pointer;

		&Wrapper {
			display: flex;
			gap: spacing(2);
			align-items: center;
		}
	}

	&Info {
		margin-bottom: spacing(2);
	}
}

.logo {
	width: 60px;
	height: 60px;

	border-radius: 4px;

	object-fit: contain;
}
