@import 'shared/styles/global';

.header {
	display: flex;
	padding: 6px  24px 6px 6px;
	align-items: center;

	border-bottom: solid 1px $c-grey;

	&Section {
		display: flex;
		gap: spacing(1);
		align-items: center;

		&Right {
			margin-left: auto;
		}
	}
}

.back {
	margin-right: spacing(2);

	transform: rotate(90deg);
}

.button {
	padding: spacing(1);

	&Employees {
		margin-left: auto;
	}

	&Reveal {
		padding: spacing(1, 3);
	}

	&Disabled {
		opacity: 50%;

		pointer-events: none;
	}
}

.icon {
	color: $c-dark-grey;

	&Previous {
		transform: rotate(180deg);
	}
}
