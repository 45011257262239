@import "shared/styles/global";

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.title {
    display: flex;
    gap: spacing(0.5);

    align-self: flex-start;

    color: #222;
    font-family: $f-inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.body {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.university {
    color: #000;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.dates {
    color: #4D515C;
    font-family: $f-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
