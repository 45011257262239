$c-very-light-grey: #f8f8f8;
$c-light-grey: #fbfbfc;
$c-lighter-grey: #f1f1f1;
$c-grey: #d9d9d9;
$c-light-dark-grey: #9f9d9d;
$c-dark-grey: #868686;
$c-dark-purple: #4244dc;
$c-another-grey: #676767;
$c-lavender: #f1f1ff;
$c-purple: #ddd7ff;
$c-light-purple: #eae6fe;
$c-very-dark-green: #2a312e;
$c-dark-green: #006a37;
$c-dark-green-20: rgba($c-dark-green, .2);
$c-green: #66a687;
$c-bright-green: #57d18a;
$c-bright-green-20: rgba($c-bright-green, .2);
$c-green-40: rgba(#66a687, .4);
$c-yellow: #fbb85a;
$c-yellow-20: rgba(#fbb85a, .2);
$c-light-green: #d6ffee;
$c-very-light-green: #e6f6f0;
$c-red-error: #ff314a;
$c-red-danger: #f54245;
$c-red-danger-20: rgba($c-red-danger, .2);
$c-light-red: #f28587;
$c-light-error: #ffe0e4;
$c-red-error-20: rgba($c-red-error, .2);
$c-primary-gradient-left: #4ee036;
$c-primary-gradient-right: #89d5ff;
$c-background-green: #e4fcef;
$c-success: #37ca83;
$c-dark-blue: #10111a;
$c-blue: #182c4c;
$c-linkedin: #1569bf;
$c-twitter: #1d9bf0;
$c-link-blue: #06f;
$c-light-blue: #1e78ff;
$c-light-extra-blue: #ddebff;
$c-extra-light-green: #E6F7F0;
$c-black-04: rgba(black, .04);
$c-shadow: 0 -10px 20px rgba(160, 160, 160, .1), 0 30px 40px rgba(170, 170, 170, .2);
$c-box-shadow: 0 -5px 60px rgba(205, 205, 205, .1), 0 20px 60px rgba(138, 149, 158, .15);
$c-gradient-10: linear-gradient(94.98deg, rgba(78, 224, 54, .1) -26.18%, rgba(137, 213, 255, .1) 126.51%);
