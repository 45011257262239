@import 'shared/styles/global';

.wrapper {
	display: flex;
}

.button {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.profile {
	flex-direction: column;
	gap: spacing(1);
	margin-bottom: spacing(1);
	padding: spacing(2, 0);

	&:hover {
		background: $c-light-grey;

		.project, .profileCheckbox {
			visibility: visible;
			opacity: 1;
		}
	}

	&Checkbox {
		margin-left: spacing(3);

		visibility: hidden;
		opacity: 0;

		transition: $transition;
		transition-property: opacity;

		&Visible {
			visibility: visible;
			opacity: 1;
		}
	}

	&Avatar {
		flex: 1 0 auto;
		max-width: 60px;
	}

	&Text {
		margin-left: spacing(2);
	}

	&Name {
		margin-bottom: spacing(1);
	}

	&Date {
		text-transform: lowercase;
	}

	&Expires {
		margin-top: 21px;
		padding: spacing(0.5, 1);
		border-radius: 1px;

		background-color: $c-lighter-grey;
	}
}

.contacts {
	position: initial;

	padding: spacing(0.5, 0);

	&Social {
		gap: 6px;
	}

	&Mail {
		gap: spacing(1);
		margin-left: 76px;

		color: $c-dark-grey;

		&Icon {
			min-width: 20px;
		}
	}

	&Link {
		z-index: 5;

		text-decoration: none;
	}
}

.icon {
	&Github {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Linkedin {
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Twitter {
		color: $c-twitter;

		&:hover, &:active {
			color: $c-twitter;
		}
	}
}

.icons {
	display: flex;
	gap: spacing(1);
	align-items: center;

	color: #adadad;
}

.cell {
	max-width: 80%;
	margin-top: 25px;
}
