@import "shared/styles/global";

.info {
	padding: spacing(2, 0);
}

.row {
	display: flex;

	&:not(:last-child) {
		margin-bottom: spacing(2);
	}

	&Name {
		display: inline-block;
		align-self: center;
		width: 145px;
		margin-right: spacing(2);

		overflow-wrap: break-word;

		&Top {
			align-self: start;
			margin-top: spacing(1);
		}
	}
}

.tags {
	width: 50%;
	display: flex;
}

.tag {
	padding: spacing(0.5, 1);

	&Intent {
		background: $c-background-green;
	}

	&Tech {
		background: #E5E0FF;
	}
	
	&Wrapper {
		display: flex;
		gap: spacing(1);
		flex-wrap: wrap;
	}
}
