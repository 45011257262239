@import "shared/styles/global";

.container {
    display: flex;
    min-width: 336px;
    max-width: 336px;
    padding: 16px;
    align-items: center;
    height: 100%;

    border-radius: 16px;
    border: 1px solid #E5E5E5;
    background: #FFF;

    @include mobile {
        min-width: 100%;
        max-width: 100%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.title {
    color: #222;
    font-family: $f-inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.text {
    color: #4D515C;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    max-width: 244px;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.name {
    color: #1476FF;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    &:hover {
        color: #0040A7;
        text-decoration: underline;
    }
}

.icon {
    color: #868686;
}

.button {
    display: flex;

    height: 100%;
    width: 100%;

    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    color: #FFF;

    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; 

    border-radius: 8px;
    background: linear-gradient(237deg, #D554E9 -57.94%, #5499E9 122.84%);

    &:hover {
        background: linear-gradient(0deg, #3431B7 0%, #3431B7 100%), linear-gradient(21deg, #D554E9 -186.36%, #5499E9 165.88%);
    }
}

.colleagues {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    &Wrapper {
        display: flex;
        gap: 12px;
    }
}

.inline {
    display: flex;
    align-items: center;
    gap: 8px;
}
