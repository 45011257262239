@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

a {
	font-family: $f-inter;
	text-decoration: none;
	color: inherit;
}

ul {
	list-style-type: none;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $f-inter;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	max-width: 1180px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.optoutInner {
	display: flex;
	width: 100%;
	padding: 120px 0;
	justify-content: space-between;
	margin: 30px 0 100px;
}

.formContainer {
	display: flex;
	flex-direction: column;
	max-height: 280px;
	max-width: 500px;
	background-image: linear-gradient(135deg, #0097ddae, #00d85e98);
	border-radius: 22px;
	align-items: center;
	justify-content: center;
	gap: 26px;
	padding: 60px;
	position: relative;
}

.optoutDescr {
	max-width: 550px;
	padding-right: 20px;
}

.descrTitle {
	font-size: 36px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 40px;
}

.formTitle {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
}

.responseContainer {
	display: flex;
	flex-direction: column;
	height: 280px;
	width: 500px;
	background-image: linear-gradient(225deg, #d554e9, #5499e9);
	opacity: 0.8;
	border-radius: 22px;
	align-items: center;
	justify-content: center;
	padding: 60px;
}

.responseTitle {
	color: #fff;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
}

.formInner {
	display: flex;
	flex-direction: column;
}

.inputBlock {
	display: flex;
	gap: 12px;
	align-self: start;
}

.errorEmail {
	position: absolute;
	bottom: 48px;
	left: 60px;
	color: red;
	font-size: 14px;
}

.form {
	display: flex;
	padding: 8px 12px;
	background-color: #fff;
	width: 100%;
	height: 50px;
	border-radius: 12px;
}

.formInput {
	max-width: 260px;
	font-size: 18px;
}

.formBtn {
	height: 50px;
	border-radius: 12px;
	color: #fff;
	background-color: #000000d5;
	padding: 12px 18px;
	position: relative;
	transition: color 0.2s ease;
}

.formBtn::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 14px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.formBtn:hover {
	color: #fbf9f7;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
}

.formBtn:hover::before {
	opacity: 1;
}

@media (max-width: 980px) {
	.container {
		padding: 0 20px;
	}

	.optoutInner {
		flex-direction: column;
		gap: 50px;
		align-items: center;
		padding: 30px 0;
		margin-bottom: 80px;
	}

	.optoutDescr {
		max-width: 500px;
		padding-right: 0;
	}

	.errorEmail {
		bottom: 35px;
		left: 70px;
	}
}

@media (max-width: 580px) {
	.formContainer {
		padding: 50px 40px;
	}

	.errorEmail {
		bottom: 25px;
		left: 45px;
	}
}

@media (max-width: 480px) {
	.optoutInner{
		padding: 0;
	}

		.descrTitle{
		font-size: 28px;
	}

	.formContainer {
		padding: 40px 30px;
	}

	.formInput {
		width: 100%;
	}

	.inputBlock {
		flex-direction: column;
		width: 100%;
		gap: 25px;
	}

	.errorEmail {
		bottom: 88px;
		left: 40px;
	}
}

@media (max-width: 400px) {
	.formTitle {
		font-size: 22px;
	}
}
