@import "shared/styles/global";

.info {
	padding: spacing(3, 2);
}

.row {
	display: flex;

	&:not(:last-child) {
		margin-bottom: spacing(2);
	}

	&Name {
		display: inline-block;
		align-self: center;
		width: 145px;
		margin-right: spacing(2);

		overflow-wrap: break-word;
	}
}
