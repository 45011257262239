@import "shared/styles/global";

.wrapper {
    display: flex;
    align-items: center;
    gap: spacing(1);

    padding: spacing(0.75, 1);

    border-radius: 2px;

    background: $c-lighter-grey;

    cursor: pointer;

    &Disabled {
        cursor: not-allowed;
    }
}