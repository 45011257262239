@import "shared/styles/global";

.container {
    display: flex;
    padding: 16px 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    border-radius: 16px;
    border: 1px solid #E5E5E5;

    background: #F4F1FF;
}

.title {
    display: flex;
    gap: spacing(0.5);

    align-self: flex-start;

    color: #222;
    font-family: $f-inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.body {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.summary {
    color: #222;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.tags {
    display: flex;
    flex-direction: column;
    gap: spacing(0.5);

    &Title {
        color: #110F60;
        font-family: $f-inter;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 18px;
    }

    &Wrapper {
        display: flex;
        gap: spacing(1);
        flex-wrap: wrap;
    }
}

.tag {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #FFF;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    border-radius: 4px;
    background: #5A67E6;
}
