@import "shared/styles/global";

.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.row {
	z-index: 5;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	cursor: pointer;
}

.blur {
	filter: blur(3px);

	--webkit-user-select: none;
	--ms-user-select: none;
	user-select: none;
}

.tooltip {
	max-width: 195px;

	&Credits {
		color: $c-success;
	}
}

.inline {
	display: flex;
	flex-direction: row;
	gap: spacing(1);
}

.text {
	margin-bottom: spacing(1);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.icon {
	z-index: 2;

	color: $c-dark-grey;

	cursor: pointer;

	&:hover {
		color: $c-light-dark-grey;
	}
}
