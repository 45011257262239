@import 'shared/styles/global';

.container {
	max-width: 1100px;
	margin: 0 auto;
	margin-top: spacing(3.5);
	padding: 0 70px;

	@include laptop {
		padding: spacing(0, 4);
	}
}

.dropdown {
	margin-top: spacing(4);
	margin-bottom: spacing(8);
}

.text {
	width: 500px;
	display: flex;
	justify-content: space-around;
	align-items: center;

	padding: spacing(1.5, 0);

	border: solid 1px #d9d9d9;

	cursor: pointer;

	&:hover {
		background-color: $c-light-grey;
	}
}

.history {
	display: flex;
	gap: spacing(1);
	align-items: center;

	margin-bottom: spacing(4);

	&Icon {
		color: $c-dark-grey;
	}
}