.layout {
	display: grid;
	grid-template:
		'sidebar header' 52px
		'sidebar content' auto;
	grid-template-columns: 64px auto;
}

.header {
	position: sticky;
	top: 0;

	grid-area: header;
}

.sidebar {
	position: sticky;
	top: 0;

	grid-area: sidebar;
}

.content {
	grid-area: content;
}
