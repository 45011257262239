@import 'shared/styles/global';

.container {
	width: 500px;
	margin: 42px auto 0;
}

.form {
	width: 300px;
	margin: 0 auto;

	&Title {
		margin-bottom: 24px;
	}

	&Description {
		margin-bottom: 24px;

		text-align: center;
	}

	&Wrapper {
		padding: 50px 81px 39px;

		box-shadow: $c-box-shadow;
	}

	&Company {
		margin-bottom: 36px;
	}
}

.email {
	margin-bottom: 32px;

	&Title {
		margin-bottom: 8px;

		cursor: default;
	}

	&Link {
		color: $c-dark-grey;
		text-decoration: underline !important;

		&:hover {
			color: $c-dark-grey;
			text-decoration: underline !important;
		}
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	align-items: center;
}
