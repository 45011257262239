@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	align-items: center;
	
	padding: spacing(7.5, 4);
	margin: 0 auto;
}

.header {
	display: flex;
	gap: spacing(1);
	align-self: flex-start;

	&Directory {
		color: #4D515C;
		font-family: $f-inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	&Name {
		color: #110F60;
		font-family: $f-inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
	}
}

.profile {
	display: flex;
	flex-direction: column;
	gap: spacing(3.75);
	width: 100%;

	&Body {
		display: flex;
		gap: spacing(2);

		@include mobile {
			flex-direction: column;
		}
	}

	&Content {
		display: flex;
		flex-direction: column;
		gap: spacing(5);
	}
}


@media (max-width: 1200px) {
	.priceItems {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 45px;
		justify-items: center;
	}

	.priceItem:nth-child(3) {
		grid-column: span 2;
	}
}

@media (max-width: 768px) {
	.section {
		margin-top: 50px;
		padding: 0 8px;
	}

	.sectionTitle {
		font-size: 44px;
	}

	.priceItems {
		display: flex;
		flex-direction: column;
		margin-top: 70px;
	}
}
@media (max-width: 560px) {
	.section {
		margin: 50px 0 60px;
	}

	.sectionTitle {
		font-size: 36px;
	}

	.priceItems {
		margin-top: 50px;
		gap: 35px;
	}

	.faqTitle {
		font-size: 32px;
	}

	.faqSection {
		padding: 0 8px;
		margin-bottom: 50px;
	}
}

@media (max-width: 445px) {
	.sectionTitle {
		font-size: 30px;
	}

	.sectionText {
		text-align: center;
		font-size: 18px;
	}

	.section{
		margin: 35px 0 50px;
		
	}
}

@media (max-width: 400px) {
	.sectionTitle {
		font-size: 28px;
	}
}
