@import 'shared/styles/global';

.footer {
	font-family: $f-inter;
	font-weight: 500;
	color: #222;
	font-size: 14px;
	background-color: #000;
	height: 100%;
}

.footer__inner {
	padding: 110px 32px 60px;
}

.footer__content {
	display: flex;
	justify-content: center;
	gap: 50px;
	margin-bottom: 90px;
}

.footer__descr {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.footer__title {
	max-width: 600px;
	margin-bottom: 6vh;
	color: #fbf9f7;
	font-size: 44px;
	font-weight: 600;
	line-height: 60px;
}

.footer__button {
	line-height: 22px;
	display: flex;
	justify-content: center;
	align-self: center;
	text-align: center;
	font-size: 18px;
	padding: 13px 20px 16px;
	background-image: linear-gradient(225deg, #e37ef3, #74aff3);
}

.contact__formWrap {
	width: 100%;
	max-width: 550px;
	display: flex;
	flex-direction: column;
	justify-self: start;
}

.contact__formTitle {
	font-weight: 500;
	font-size: 28px;
	line-height: 108%;
	letter-spacing: -0.01em;
	color: #fff;
	margin-bottom: 3vh;
}

.contact__formDescr {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: #fff;
	margin-bottom: 6vh;
}

.contact__fields {
	display: flex;
	gap: 12px;
}

.contact__fields input {
	height: 52px;
	background-color: #fff;
	color: #222;
	font-family: $f-inter;
	font-size: 16px;
	font-weight: 500;
	border: none;
	border-radius: 15px;
	padding: 0 21px;
	max-width: 250px;
}

.form__button {
	font-family: $f-inter;
	font-size: 16px;
	font-weight: 700;
	border-radius: 15px;
	color: #222;
	padding: 16px 18px;
	background-color: #fff;
	transition: background-color 0.3s ease;
}

.iframe__block {
	width: 432px;
	max-width: 432px;
	height: 100%;
	border-radius: 15px;
	background-color: #000;
	overflow: hidden;
}

.footer__menu {
	margin: 0 20px 15px;
	display: flex;
	padding: 16px 30px;
	justify-content: space-between;
}

.footer__menuList {
	display: flex;
	flex-direction: column;
	gap: spacing(1.5);

	&Wrapper {
		display: flex;
		flex-direction: row;
		gap: 46px;
	}
}

.footer_menuItem {
	opacity: 0.8;
	max-width: 210px;
}

.opacity_80 {
	opacity: 0.8;
}

.nowrap {
	white-space: nowrap;
}

.footer__menuLogo {
	display: flex;
	flex-direction: column;
	gap: 7px;
}

.footer__menuItemLink {
	cursor: pointer;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	line-height: 18px;
	opacity: 0.8;

	&:hover {
		opacity: 1;
	}
}

.chapters {
	margin: 0 20px 15px;
	display: flex;
	padding: 16px 30px;
	gap: 8px;
	align-items: center;
	flex-wrap: wrap;

	@include tablet {
		margin: 0;
		padding: 8px 8px 16px;
	}	
}

.footer__menuItemSocial {
	cursor: pointer;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	color: #7daedb;
}

.footerLogo {
	display: flex;
	align-items: center;
	color: #fff;
	width: 182px;
	z-index: 0;
}

.directory {
	display: flex;
	gap: 8px;

	align-items: center;

	&Active {
		span {
			opacity: 1;
		}
	}
}

.icon {
	color: white;
	opacity: 0.8;
	transform: rotate(180deg);

	cursor: pointer;

	&Active {
		opacity: 1;
		transform: none;
	}
}

.directoryContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 60px;
	padding: 0 50px;

	@include mobile {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}

.bannerContainer {
	display: flex;
	gap: 20px;
	align-self: flex-start;
	justify-content: center;
	align-items: center;
	top: 100px;

	@include mobile {
		order: -1;
		align-self: center;
	}
}

@media (max-width: 1080px) {
	.footer__content {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.footer__inner {
		flex-direction: column;
		align-items: center;
		gap: 60px;
	}

	.footer__menu {
		height: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
	}

	.footer__menuList {
		gap: 20px;
	}

	.footer__menuLogo {
		align-items: center;
	}

	.directoryContainer {
		margin-top: 20px;
		padding: 10px;
	}
}

@media (max-width: 768px) {
	.footer__inner {
		padding-top: 70px;
		padding-bottom: 50px;
	}

	.footer__title {
		font-size: 32px;
		line-height: 44px;
	}

	.footer__content {
		margin-bottom: 5vh;
	}

	.footer__menuList{
		gap: 10px;
		align-items: center;
	}

	.footer__menuListWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.footer_menuItem {
		text-align: center;
	}
}

@media (max-width: 660px) {
	.footer__menuList {
		flex-direction: column;
		gap: 10px;
	}

	.socialLinks{
		gap: 25px;
	}
}

@media (max-width: 540px) {
	.contact__fields {
		flex-direction: column;
		gap: 15px;
	}

	.contact__fields input {
		padding: 10px;
		text-align: center;
	}

	.iframe__block {
		width: 390px;
	}

	.footer__social {
		gap: 40px;
	}
}

@media (max-width: 420px) {
	.footer__inner {
		padding-left: 10px;
		padding-right: 10px;
	}

	.footer__title {
		font-size: 28px;
	}

	.iframe__block {
		width: 360px;
	}
}
