@import "shared/styles/global";

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    align-self: stretch;
}

.title {
    display: flex;
    gap: spacing(0.5);

    align-self: flex-start;

    color: #222;
    font-weight: 600;
    font-size: 20px;
    font-family: $f-inter;
    font-style: normal;
    line-height: normal;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    padding: 0 16px;
    align-self: stretch;
}

.experience {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    &Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
    }

    &Dates {
        color: #4D515C;
        font-family: $f-inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &Title {
        color: #000;
        font-family: $f-inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        &Wrapper {
            display: flex;
            align-items: center;
            gap: 4px;
            align-self: stretch;

            @include tablet {
                display: inline-block;
                width: 100%;
            }
        }
    }

    &Company {
        color: #1476FF;
        font-family: $f-inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;

        &Active {
            cursor: pointer;
        }
    }
}

.logo {
	width: 60px;
	height: 60px;

	border-radius: 4px;

	object-fit: contain;
}
