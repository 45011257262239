@import 'shared/styles/global';

$c-button-disabled: #999;

.container {
	display: flex;

	height: 100vh;

	background-size: contain;
}

.login {
	margin-top: 44px;
	margin-bottom: 44px;

	padding-top: 24px;
	padding-bottom: 38px;

	display: flex;
	flex-direction: column;

	align-items: center;

	justify-content: space-between;

	max-height: 700px;

	width: 100%;
	text-align: center;

	&Hint {
		max-width: 400px;
		margin: 0 auto;

		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}
}
