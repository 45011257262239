@import 'shared/styles/global';

.ant-tooltip {
	z-index: 100 !important
}

.ant-slider-handle {
	&::after {
		box-shadow: 0 0 0 2px $c-success !important;
	}
}

.ant-slider-track {
	background-color: $c-success !important;
}

.ant-tooltip-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	color: black !important;
	background-color: $c-background-green !important;
	box-shadow: none !important;
	font-weight: 600;
}

.ant-tooltip-arrow {
	&::before {
		background-color: $c-background-green !important;
		box-shadow: none !important;
	}

	&::after {
		box-shadow: none !important;
	}
}
