@import 'shared/styles/global';

.tag {
	display: flex;
	align-items: center;
	padding: spacing(0.5, 0.5, 0.5, 1);
	border-radius: 2px;

	font-weight: 500;

	background: $c-background-green;

	cursor: pointer;

	&Negative {
		background: $c-light-red;

		&Button {
			color: $c-red-danger !important;
		}
	}

	&People {
		background: #E0F4FF;
	}

	&Companies {
		background: #E0E0FF;;
	}

	button {
		height: 20px;

		color: #2A312E;
	}
}
