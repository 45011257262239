@import 'shared/styles/global';

.header {
	z-index: 100;

	display: flex;
	
	align-items: center;

	position: relative;
	
	padding: spacing(0, 2);
	border-bottom: solid 1px $c-grey;

	background: transparent;

	&Results {
		@include laptop {
			padding-left: 60px;
		}
	}

	&Search {
		border-bottom: none;
	}

	&Enrichment{
		background: none;
	}

	&OpenCandidateModal {
		z-index: 10000;

		background: white;
	}

	&Plan {
		z-index: 10000;

		background: white;
	}
}

.search {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	&Results {
		left: 300px;
		transform: none;
	}
}

.logout {
	width: 100%;

	text-align: left;
}

.profile {
	&Info {
		position: relative;

		display: flex;
		gap: spacing(2);
		align-items: center;

		margin-left: auto;
	}

	&Balance {
		display: flex;
		gap: spacing(1);
		align-items: center;
		padding: spacing(1);

		color: $c-very-dark-green;
	}

	&Account {
		display: flex;
		gap: 4px;
		align-items: center;

		cursor: pointer;

		&Overlay {
			z-index: 10001;
		}

		&Dropdown {
			color: $c-grey;
		}
	}
}

.tooltip {
	&Overlay {
		z-index: 10001;
	}

	&Container {
		background: white;
	}

	&Text {
		padding: 10px;
	}

	&Title {
		border-bottom: 1px solid $c-grey;
	}
}

.dropdownImportant {
	font-weight: 600;
}

.gradientBox {
	border: solid 1px transparent;
	border-radius: 50%;

	&Before {
		margin: -1px;

		background: linear-gradient(to bottom right, #6eca7d, #fbb85a);
	}
}
