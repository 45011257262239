@import "shared/styles/global";

.content {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
	max-width: 878px;
}

.plan {
	display: flex;
	gap: spacing(3);

	&Left {
		min-width: 200px;
	}

	&Right {
		flex: 1;
	}

	&Info {
		width: 100%;
		margin-bottom: spacing(3);
		padding: spacing(2);
		border-radius: 2px;

		background-color: white;
	}

	&Title {
		margin-bottom: spacing(3);

		text-transform: capitalize;
	}

	&Price {
		margin-bottom: spacing(1);
	}
}

.contact {
	color: rgba($c-link-blue, .78);
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.manage {
	color: $c-light-blue;
}
