@import 'shared/styles/global';

.gridContainer {
    width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px 16px;
}

.wrapper {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
	.gridContainer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px 16px;
	}
}

@media (max-width: 445px) {
	.gridContainer {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 10px 16px;
	}
}
