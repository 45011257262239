@import "shared/styles/global";

.empty {
	gap: spacing(5);
	height: 100vh;

	color: transparent;
}

.text {
	max-width: 250px;
}
