@import "shared/styles/global";

$header-height: 121px;

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3);

	background-color: white;

	&Left {
		display: flex;
		gap: spacing(3);
		align-items: center;
	}
}

.table {
	height: calc(100vh - $header-height);
	overflow-y: scroll;

	&Header {
		position: sticky;
		top: 0;
		z-index: 10;

		margin-bottom: spacing(1);
	}
}

.notifications {
	padding: 10px;
}

.notification {
	display: flex;
	gap: spacing(2);
	align-items: center;
	padding: spacing(2);
	border-radius: 2px;

	&Adding {
		background-color: $c-very-light-green;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spin {
	color: $c-very-dark-green;

	animation: spin 1.5s linear infinite;
}
