@import 'shared/styles/global';

$c-button-disabled: #999;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: spacing(10);

	width: 400px;

	text-align: center;

	&Header {
		margin-bottom: spacing(5.5);
        align-self: flex-start;
	}

	&Button {
		position: relative;

		display: flex;

		gap: spacing(2);

		align-items: center;

		justify-content: center;

		width: 100%;
		height: 48px;

		border-radius: 8px;

		color: white;

		font-family: $f-inter;

		text-decoration: none;

		background: black;

		margin-bottom: spacing(4);

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}

		&:hover {
			background: #1E78FF;
		}
	}
}

.form {
	width: 400px;
	margin-bottom: spacing(2);
}

.input {
	border: 1px solid var(#D9D9D9);
	border-radius: 8px;

	background: #FFF;
}

.link {
	margin-bottom: spacing(1);

	color: #182C4C;

	&:hover {
		text-decoration: underline;
	}
}

.email {
	display: flex;
	flex-direction: column;
	gap: spacing(1);
	align-items: self-start;
	margin-bottom: spacing(4);
}

.gap {
	margin-top: spacing(5.5);
}

.header {
    margin-top: spacing(1.5);
    margin-bottom: spacing(3);
}

.body {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    align-self: start;
    margin-bottom: spacing(5);
	text-align: left;

    &Header {
        span {
            color: black;
            font-weight: 700;
        }
    }
}

.button {
    color: #182C4C;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
