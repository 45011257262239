@import "shared/styles/global";

.buttons {
	display: flex;
	gap: spacing(3);
}

.form {
	max-width: 878px;

	&Item {
		display: flex;
		gap: spacing(3);

		&Label {
			min-width: 200px;
		}

		div {
			width: 100%;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
}

.email {
	padding: spacing(2, 0);
}

.logout {
	position: absolute;
	bottom: spacing(10);

	width: 204px;
}

.generate {
	margin-top: -8px;
	margin-left: -24px;
	color: $c-light-blue;
}

.regenerate {
	margin-left: -12px;
}

.token {
	cursor: pointer;
	display: flex;
	gap: spacing(1);

	&Icon {
		color: $c-dark-grey;

		&:hover {
			color: $c-light-dark-grey;
		}
	}
}
