@import "shared/styles/global";

.link {
	display: flex;
	gap: spacing(2);
	padding: spacing(1.5, 2);

	text-decoration: none;

	&Active {
		border-right: solid 4px $c-bright-green;

		background: $c-very-light-green;
	}

	&:hover {
		.checkbox {
			visibility: visible;
			opacity: 1;
		}
	}
}

.checkbox {
	visibility: hidden;
	opacity: 0;

	transition: $transition;
	transition-property: opacity;

	&Visible {
		visibility: visible;
		opacity: 1;
	}
}

.title {
	margin-bottom: spacing(1);
}
