@import "shared/styles/global";

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 16px 16px 24px;
    align-self: stretch;

    border-radius: 16px;
    border: 1px solid #E5E5E5;
    background: #FFF;
}

.title {
    color: #222;
    font-family: $f-inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wrapper {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.question {
    padding: 8px 0;
    color: #000;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.answer {
    padding: 8px 0;
    color: #4D515C;
    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}