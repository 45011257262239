@import "shared/styles/global";

.logo {
	object-fit: contain;
	border-radius: 100%;
}

.job {
	background: white;
}
