@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	gap: spacing(5);
	align-items: center;
	
	padding: spacing(7.5, 4);
	margin: 0 auto;
}

.title {
	font-family: $f-inter;
	font-size: 40px;
	font-weight: 700;
	letter-spacing: 0;
	text-align: center;
	margin-bottom: 30px;
}

.gridContainer {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px 16px;
}

.name {
	color: #1476FF;
	font-family: $f-inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	max-width: 250px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		color: #0040A7;
		text-decoration: underline;
	}
}

.section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 80px 0 100px;
}


@media (max-width: 1200px) {
	.priceItems {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 45px;
		justify-items: center;
	}

	.priceItem:nth-child(3) {
		grid-column: span 2;
	}
}

@media (max-width: 768px) {
	.section {
		margin-top: 50px;
		padding: 0 8px;
	}

	.sectionTitle {
		font-size: 44px;
	}

	.priceItems {
		display: flex;
		flex-direction: column;
		margin-top: 70px;
	}

	.gridContainer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px 16px;
	}
}
@media (max-width: 560px) {
	.section {
		margin: 50px 0 60px;
	}

	.sectionTitle {
		font-size: 36px;
	}

	.priceItems {
		margin-top: 50px;
		gap: 35px;
	}

	.faqTitle {
		font-size: 32px;
	}

	.faqSection {
		padding: 0 8px;
		margin-bottom: 50px;
	}
}

@media (max-width: 445px) {
	.sectionTitle {
		font-size: 30px;
	}

	.sectionText {
		text-align: center;
		font-size: 18px;
	}

	.section{
		margin: 35px 0 50px;
	}

	.title {
		font-family: $f-inter;
		font-size: 28px;
		font-weight: 700;
		letter-spacing: 0;
		text-align: center;
		margin-bottom: 20px;
	}

	.container {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		align-items: center;
		
		padding: spacing(2, 1);
		margin: 0 auto;
	}

	.gridContainer {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 10px 16px;
	}
}

@media (max-width: 400px) {
	.sectionTitle {
		font-size: 28px;
	}
}
