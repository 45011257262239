@import "shared/styles/global";

.wrapper {
    flex: 1;
    margin-bottom: spacing(5);
}

.card {
    display: flex;
    flex-direction: column;
    padding: spacing(2, 0);

    border: 1px solid $c-lighter-grey;
    border-radius: 4px;

    overflow: hidden;

    transition: $transition;
    transition-property: height;

    &Item {
        display: flex;
        gap: spacing(1);
        padding: spacing(1, 4);
    }

    &Text {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
}

.title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
}

.link {
    text-decoration: none;

    cursor: pointer;
}

.seeMore {
    margin-top: spacing(2);

    text-align: center;

    &Text {
        padding: spacing(0.25, 0.5);

        cursor: pointer;
    }
}

.text {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}