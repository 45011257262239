@import "shared/styles/global";

.aside {
	z-index: 10000;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
	padding: 10px 0 18px;

	color: white;

	background-color: #10111a;

	nav {
		display: flex;
		flex-direction: column;
		gap: 16px;

		// .logo {
		// 	margin-bottom: 8px;
		// }
	}
}


.link {
	display: flex;
	align-items: center;
	justify-content: center;

	color: $c-dark-grey;
	
	&Active {
		color: white;
	}
}
