@import 'shared/styles/global';

$controls-height: 101px;

.profile {
	display: grid;
	height: calc(100vh - $controls-height);

	grid-template-columns: 1fr 350px;
}

.wrapper {
	border-right: solid 1px $c-grey;
	overflow-y: scroll;
}

.info {
	display: flex;
	gap: spacing(3);
	padding: spacing(3);
	border-bottom: solid 1px $c-grey;

	&Avatar {
		min-width: 88px;
	}

	&Column {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
	}
}

.sections {
	padding: spacing(0, 3);

	section {
		border-bottom: solid 1px $c-lighter-grey;
	}
}

.location {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}
}
