@import 'shared/styles/global';

$c-button-disabled: #999;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: spacing(10);

	width: 400px;

	text-align: center;

	&Header {
		margin-bottom: spacing(4);
	}

	&Button {
		position: relative;

		display: flex;

		gap: spacing(2);

		align-items: center;

		justify-content: center;

		width: 100%;
		height: 48px;

		border-radius: 8px;

		color: white;

		font-family: $f-inter;

		text-decoration: none;

		background: black;

		margin-bottom: spacing(2);

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}

		&:hover {
			background: #1E78FF;
		}
	}
}

.form {
	width: 400px;
	margin-bottom: spacing(2);
}

.input {
	border: 1px solid var(#D9D9D9);
	border-radius: 8px;

	background: #FFF;
}
