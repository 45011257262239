@import "shared/styles/global";

.title {
	margin-bottom: spacing(4);

	text-align: center;
}

.questions {
	max-width: 900px;
	margin: 0 auto 32px;
}

.question {
	border-bottom: solid 1px $c-grey;

	&:first-child {
		border-top: solid 1px $c-grey;
	}
}

.contact {
	text-align: center;

	a {
		color: black;
	}
}
