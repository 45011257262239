@import 'shared/styles/global';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;

	font: inherit;
	vertical-align: baseline;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid rgba($c-light-grey, .7);
		border-radius: 10px;

		background-color: $c-grey;
		background-clip: padding-box;
	}

	&::-webkit-scrollbar-track {
		background: white;
	}
}

button,
a {
	color: inherit;

	cursor: pointer;
}

button {
	display: inline-block;
}

textarea,
input,
button,
a {
	border: none;

	font: inherit;

	background: none;

	outline: none;
}

body {
	font-size: 14px;
	font-family: $f-space-grotesk;
}

.ant-form-item-label {
	font-weight: 500;

	label {
		width: 100%;
	}
}

.ant-modal {
	padding-bottom: 0;

	&-wrap {
		overflow-y: hidden !important;
	}
}

.ant-notification-notice-message {
	padding-inline-end: 0 !important;
}

.ant-progress-bg {
	background-color: transparent !important;
}

.ant-form-item-explain-error {
	margin: spacing(1, 0);
}

.ant-checkbox {
	top: 0;
}

.ant-checkbox-wrapper {
	display: flex;
	align-items: center;

	line-height: 1;

	&::after {
		display: none;
	}
}

.ant-progress-circle-trail {
	stroke: $c-lighter-grey !important;
}

.ant-upload-drag {
	border-style: solid !important;
}
