@import 'shared/styles/global';

$controls-height: 101px;

.profile {
	// display: grid;

	height: calc(100vh - $controls-height);
	overflow-y: scroll;

	// grid-template-columns: 1fr 350px;
}

.wrapper {
	border-right: solid 1px $c-grey;
	overflow-y: scroll;
	margin-bottom: spacing(3);
}

.info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: spacing(3);
	padding: spacing(3);
	border-bottom: solid 1px $c-grey;

	&Avatar {
		min-width: 88px;
	}

	&Column {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
	}

	&Row {
		display: flex;
		gap: spacing(3);
	}
}

.sections {
	padding: spacing(0, 3);

	section {
		border-bottom: solid 1px $c-lighter-grey;
	}
}

.location {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}
}

.title {
	height: 44px;
	margin-bottom: 1px;
	padding: 11px 0;
}

.bio {
	// padding: spacing(1, 0, 0, 2);
	
	margin-bottom: spacing(3);
}

.column {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
}

.leader {
	display: flex;
	gap: spacing(2);
	
	&Name {
		font-size: 15px;
		font-weight: 600;
		line-height: 120%;

		color: #1E78FF;

		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&Column {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&Row {
		display: flex;
		gap: spacing(1);
		align-items: center;
	}

	&Linkedin {
		color: $c-linkedin;

		cursor: pointer;
	}

	&Icon {
		color: #868686;
	}
}

.icons {
	display: flex;
	gap: spacing(1);

	&Linkedin {
		height: 20px;
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Website {
		height: 20px;
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}
}

.logo {
	width: 60px;
	height: 60px;

	border-radius: 4px;

	object-fit: contain;
}
