@import "shared/styles/global";

$height-header: 52px;

.container {
	display: grid;
	grid-template-columns: 300px 1fr;
	height: calc(100vh - $height-header);
	overflow-y: hidden;

	@include laptop {
		display: block;
	}
}
