@import 'shared/styles/global';

.open {
	&Button {
		position: absolute;
		top: spacing(1);
		left: spacing(9);
		z-index: 10000;

		display: none;
		padding: spacing(1);

		color: $c-dark-grey;

		@include laptop {
			display: block;
		}
	}
}

.form {
	&Title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
		padding: 0 10px 0 16px;
		border-bottom: solid 1px $c-grey;

		span {
			text-transform: uppercase;
		}
	}

	&Height {
		height: calc(100vh - 210px);
		overflow-y: scroll;
	}

	&Item {
		padding: spacing(2);	
		
		:global(.ant-form-item-no-colon) {
			font-size: 14px !important;
			font-weight: 400;
			font-family: Inter, sans-serif;
			line-height: 120%;
		}
		

		:global(.ant-form-item) {
			margin: spacing(0);
		}
	}

	&Field {
		margin-bottom: 0;

		&Checkbox {
			padding: spacing(2);

			transition: $transition;
			transition-property: background-color;

			&:hover {
				background-color: $c-light-grey;

				.clear {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&:not(:last-child) {
			border-bottom: solid 1px $c-lighter-grey;
		}
	}

	&Container {
		z-index: 100;

		max-width: 300px;

		border-right: 1px solid $c-grey;

		background-color: white;

		@include laptop {
			display: none;
		}

		&Open {
			@include laptop {
				position: absolute;

				display: block;

				box-shadow: 2px 0 16px rgba(14, 3, 83, 0.15);
			}
		}
	}

	&Buttons {
		position: sticky;
		bottom: 0;

		display: flex;
		flex-direction: column;
		gap: spacing(0.5);
		padding: spacing(2);
		border-top: solid 1px $c-grey;

		background: white;
		
		&Filter {
			color: #4244dc;
		}
	}
}

.rockstar {
	width: 289px;
	margin: spacing(1, 0, 0, -2);
	padding: spacing(2, 2, 0);
	border-top: solid 1px $c-lighter-grey;

	&Label {
		margin-bottom: spacing(1);

		cursor: default;
	}
}

.topSchool {
	margin: spacing(0, 0, -3, 0);
}

.row {
	display: flex;
	width: 100%;

	&Funding {
		gap: 16px;
	}
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Funding {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&Icon {
		color: $c-dark-grey;
	}

	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}

.tooltip {
	width: 328px;
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.clear {
	visibility: hidden;
	opacity: 0;

	transition: $transition;
	transition-property: opacity;
}

.advanced {
	display: flex;
	align-items: center;

	cursor: pointer;

	margin-top: spacing(1);
	margin-left: spacing(1.5);

	&Icon {
		transform: rotate(-90deg);;
	}

	&Open {
		margin-bottom: spacing(1);
	}
}

.attach {
	display: flex;
	margin-left: spacing(2);
	margin-top: spacing(1);
	margin-bottom: -8px;
}

.slider {
	position: relative;
	padding-left: 6px;
	margin-top: spacing(4);

	&Row {
		display: flex;
		justify-content: space-between;
	}

	&Wrapper {
		&:hover {
			background-color: $c-light-grey;

			.clear {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
