@import "shared/styles/global";

.card {
	margin: 42px auto;
	margin-top: 0;

	opacity: 0;

	&Visible {
		opacity: 1;

		transition: $transition;
	}
}

.success {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;

	&Icon {
		width: 180px;
		height: 180px;
	}
}

.description {
	text-align: center;
}

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	padding: spacing(3);

	background-color: $c-very-light-grey;

	&Row {
		display: flex;
		justify-content: space-between;

		&Wrapper {
			display: flex;
			flex-direction: column;
			gap: spacing(1);
		}
	}

	&Interval {
		margin: spacing(1, 0, 2);
	}
}

.capitalize {
	text-transform: capitalize;
}

.contact {
	color: rgba($c-link-blue, .78);
}
