@import "shared/styles/global";

.tooltip {
	max-width: 195px;

	&Credits {
		color: $c-success;
	}
}

.contacts {
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-width: 289px;
	height: fit-content;
	min-height: 74px;
	margin-left: auto;
	padding: 12px;
	border-radius: 2px;

	background-color: #f3f3f3;

	&Open {
		border-left: solid 1px $c-lighter-grey;

		background-color: white;
	}

	&Mail {
		display: flex;
		gap: spacing(1);

		color: $c-dark-grey;

		&Closed {
			align-items: center;
		}
	}


	&List {
		text-align: left;

		list-style: none;

		li {
			display: flex;
			align-items: center;
			
			&:not(:last-child) {
				margin-bottom: spacing(1);
			}
		}

		&Primary span {
			font-weight: 600;
			font-size: 15px;
		}
	}

	&Social {
		display: flex;
		gap: spacing(1);

		color: #5e5e5e;

		a {
			height: 20px;
		}
	}
}

.icon {
	&Github {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Linkedin {
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Twitter {
		color: $c-twitter;

		&:hover, &:active {
			color: $c-twitter;
		}
	}

	&Mail {
		z-index: 2;

		color: $c-dark-grey;

		cursor: pointer;

		&:hover {
			color: $c-light-dark-grey;
		}
	}
}

.mail {
	display: flex;
	gap: 10px;
	min-width: 189px;

	&Blurred {
		filter: blur(3px);

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}
}

.inline {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: spacing(1);
}
