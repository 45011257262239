.search {
    display: inline-flex;
	padding: 4px;
	align-items: center;
	gap: 8px;

	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	background: #FFF;
	background-blend-mode: screen;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);

	&Tab {
		display: flex;
		padding: 4px 16px;
		justify-content: center;
		align-items: center;
		gap: 10px;

		opacity: 0.6;

        cursor: pointer;

		&Active{
			border-radius: 2px;
			opacity: 1;
		}

		&People {
			background: #E0F4FF;
		}

		&Companies {
			background: #E0E0FF;
		}
	}

	&Results {
		border: none;
	}
}
