@import "shared/styles/global";

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    gap: 11px;

    &Meta {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    &Name {
        color: #222;
        font-family: $f-inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &Title {
        color: #4D515C;
        font-family: $f-inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &Wrapper {
        display: flex;
        gap: spacing(3);
    }
}

.linkedin {
    color: $c-linkedin;
    cursor: pointer;
}

.button {
    display: flex;

    height: 100%;

    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    color: #FFF;

    font-family: $f-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; 

    border-radius: 8px;
    background: linear-gradient(237deg, #D554E9 -57.94%, #5499E9 122.84%);

    &:hover {
        background: linear-gradient(0deg, #3431B7 0%, #3431B7 100%), linear-gradient(21deg, #D554E9 -186.36%, #5499E9 165.88%);
    }
}
