@import 'shared/styles/global';

.header {
	display: flex;
	padding: 6px  24px 6px 6px;
	border-bottom: solid 1px $c-grey;

	&Section {
		display: flex;
		gap: spacing(1);
		align-items: center;

		&Right {
			margin-left: auto;
		}
	}
}

.back {
	margin-right: spacing(2);

	transform: rotate(90deg);
}

.button {
	padding: spacing(1);

	&Contact {
		position: relative;
	}

	&Project {
		padding: spacing(1,1,1,3);
	}

	&Disabled {
		opacity: 50%;

		pointer-events: none;
	}
}

.icon {
	color: $c-dark-grey;

	&Credits {
		position: absolute;
		right: 4px;
		bottom: 4px;

		color: #c0c2c1;
	}

	&Previous {
		transform: rotate(180deg);
	}
}
