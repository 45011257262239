.button {
	display: flex;
	justify-content: flex-end;
}

.text {
	white-space: normal;

	&Hide {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}
}
