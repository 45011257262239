@import 'shared/styles/global';

.wrapper {
	padding: spacing(2);

	transition: $transition;
	transition-property: background-color;

	&:hover {
		background-color: $c-light-grey;

		.titleButton {
			visibility: visible;
			opacity: 1;
		}
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&Margin {
		margin-bottom: spacing(1);
	}

	&Label {
		display: flex;
		align-items: center;

		cursor: default;
	}

	&Button {
		visibility: hidden;
		opacity: 0;

		transition: $transition;
		transition-property: opacity;
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(1);

	&Wrapper {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
	}
}

.tag {
	display: flex;
	align-items: center;
	padding: 4px;

	border: 1px solid $c-green;
	border-radius: 2px;

	font-weight: 500;
}

.autocomplete {
	margin-top: spacing(1);
	margin-bottom: 0;

	&Dropdown {
		max-height: 190px;
		overflow-y: scroll;
	}

	&Option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		min-height: 38px;
		padding: spacing(1);

		color: black;
		text-align: left;

		transition: $transition;
		transition-property: background-color;

		&:hover {
			background-color: $c-light-grey;
		}

		&Selected {
			&:hover {
				background-color: $c-light-grey;
			}

			span {
				font-weight: 500;
			}
		}

		span {
			display: flex;
			align-items: center;
		}
	}
}
