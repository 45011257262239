@import 'shared/styles/global';

$c-search: #f2f8ff;
$c-filters: #f2fff7;

.container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 70px;

	@include laptop {
		padding: spacing(0, 4);
	}
}

.search {
	&Buttons {
		display: flex;
		justify-content: space-between;
	}
}

.navigation {
	position: sticky;
	top: 68px;
	z-index: 10;

	display: flex;
	justify-content: space-between;

	margin-bottom: spacing(5.5);

	&People {
		background-color: $c-search;
		box-shadow: 0 -40px $c-search, 0 -80px $c-search;
	}

	&Companies {
		background-color: #ECECFF;
		box-shadow: 0 -40px #ECECFF, 0 -80px #ECECFF;
	}

	&Container {
		flex: 1;
		max-width: 960px;
		margin: 0 auto;
		margin-bottom: spacing(2);

		@include laptop {
			max-width: 1036px;
			padding: spacing(0, 4);
		}
	}

	&::after {
		position: absolute;
		bottom: -16px;

		width: 100%;
		height: 16px;

		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.9) 0%,
			rgba(255, 255, 255, 0.83) 35%,
			rgba(255, 255, 255, 0) 100%
		);

		content: '';
	}
}

.tabs {
	display: flex;
	gap: spacing(1);
}

.tab {
	position: relative;

	display: flex;
	gap: spacing(1);
	align-items: center;
	padding: spacing(1.5, 2);

	color: $c-dark-grey;

	cursor: pointer;

	&Search {
		color: $c-light-blue;

		background: white;
		border-radius: 4px;
		border: 1px solid #DDEBFF;
	}

	&Filters {
		color: $c-bright-green;

		background: white;
		border-radius: 4px;
		border: 1px solid #DDEBFF;
	}

	&Active {
		border: 1px solid #DCDCFF;
	}

	&People {
		&::before,
		&::after {
			position: absolute;
			bottom: 0;

			width: 6px;
			height: 6px;

			background-color: $c-search;

			content: '';
		}
	}

	&Companies {
		&::before,
		&::after {
			position: absolute;
			bottom: 0;

			width: 6px;
			height: 6px;

			background-color: #ECECFF;

			content: '';
		}
	}

	&Text {
		@include tablet {
			font-size: 16px;
		}
	}
}

.wrapper {
	margin-bottom: spacing(2);

	&Error {
		border-color: $c-red-error !important;
	}
}

.form {
	margin-bottom: spacing(10);
	padding: spacing(2);
	border-color: $c-lighter-grey;
	border-style: solid;
	border-width: 1px 1px 0;
	border-radius: 4px;

	box-shadow: $box-shadow-s;

	&Buttons {
		position: fixed;
		bottom: 0;
		left: 64px;

		width: calc(100% - 64px);

		padding: spacing(2, 0);
		border-top: 1px solid $c-grey;

		background: white;

		&Container {
			display: flex;
			justify-content: space-between;
			max-width: 960px;
			margin: 0 auto;

			@include laptop {
				max-width: 1036px;
				padding: spacing(0, 4);
			}
		}
	}
}

.error {
	display: flex;
	gap: spacing(1);
	align-items: center;
	margin-bottom: spacing(2);

	color: $c-red-error;
}

.review {
	display: flex;
	gap: spacing(1);
	align-items: center;
	padding: spacing(1, 2);

	color: $c-dark-grey;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spin {
	color: black;

	animation: spin 1.5s linear infinite;
}

.searchSuggestions {
	display: flex;
	gap: 24px;
	margin-top: 48px;
}

.beta {
	padding: spacing(0.25, 1);

	background-color: #A9E0FF;
}
