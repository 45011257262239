@import "shared/styles/global";

.select {
	&Wrapper {
		position: relative;

		display: flex;
		align-items: center;
	}

	&Trigger {
		transition: $transition;
		transition-property: transform;

		&Wrapper {
			width: 20px;
			height: 20px;
			margin-right: spacing(1);

			color: $c-grey;

			cursor: pointer;
		}

		&Open {
			transform: rotate(180deg);
		}
	}
}

.menu {
	display: flex;
	flex-direction: column;
	width: 184px;

	background: white;
	box-shadow: $c-shadow;

	&Button {
		display: flex;
		gap: spacing(1);
		align-items: center;
		width: 186px;
		margin-left: -1px;
		padding: spacing(1);

		color: black;

		&:hover {
			color: black !important;
		}

		&:active {
			color: black !important;
		}

		&Apply {
			border: none;
		}
	}

	&Input {
		width: 70px;
		margin-right: spacing(1);
		border: none;
		border-bottom: solid 1px $c-grey;
		border-radius: 0;

		&:hover {
			border-color: $c-grey;
		}

		input {
			padding: 0 !important;

			font-family: $f-space-grotesk;

			text-align: center !important;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			margin: 0;

			appearance: none;
		}

		input[type=number] {
			appearance: textfield;
		}
	}

	&Selection {
		display: flex;
	}

	&Select {
		width: 134px;
		margin-left: -1px;
		padding: spacing(1);
		border-bottom: none;
	}
}

.checkbox {
	margin-right: spacing(1);
}
