@import "shared/styles/global";

.update {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(0.5, 3, 0.5, 2);
	border-bottom: solid 1px $c-grey;

	background: linear-gradient(94.98deg, rgba(78, 224, 54, 0.3) -26.18%, rgba(137, 213, 255, 0.3) 126.51%), #fff;

	box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
}
