@import 'shared/styles/global';

.wrapper {
	display: flex;
	margin-bottom: spacing(1);
}

.border {
	position: absolute;
	bottom: 0;
	left: 48px;

	width: calc(100% - 60px);
	height: 1px;

	background-color: $c-lighter-grey;
}

.profile {
	flex-direction: column;
	gap: spacing(1);
	padding: spacing(2, 0);

	&:hover {
		background: $c-light-grey;
		
		.project, .profileCheckbox {
			visibility: visible;
			opacity: 1;
		}
	}

	&Checkbox {
		margin-left: spacing(2);

		&Visible {
			visibility: visible;
			opacity: 1;
		}
	}

	&Avatar {
		flex: 1 0 auto;
		max-width: 60px;
	}

	&Text {
		width: 100%;
		margin-left: 0;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&Name {
		margin-bottom: spacing(1);
	}

	&Score {
		padding: spacing(1);
		border-radius: 2px;

		background: $c-purple;
	}

	&Skills {
		display: flex;
		flex-wrap: wrap;
		gap: spacing(1);
		margin-left: 136px;
	}

	&Skill {
		padding: spacing(1);
		border-radius: 1px;

		background: #f5f5f5;
	}
}

.contacts {
	display: flex;
	align-items: center;
	padding: spacing(0.5, 17);

	&Social {
		display: flex;
		gap: 6px;
		align-items: center;
		margin-left: 142px;

		color: #adadad;
	}

	&Mail {
		margin-right: spacing(1);

		color: $c-dark-grey;
	}
}

.cell {
	padding-right: spacing(2);
	height: 3.7em; /* Accommodate three lines of text and some extra spacing */
	overflow: hidden;
}
  
.title {
	line-height: 1.2; /* Adjust line height as needed for your design */
	display: -webkit-box;
	-webkit-line-clamp: 3; /* Limit to three lines (for webkit-based browsers) */
	-webkit-box-orient: vertical;
	white-space: normal;
	text-overflow: ellipsis;
	overflow: hidden;
}

.project {
	z-index: 5;

	margin-top: spacing(3);

	color: $c-dark-grey;

	visibility: hidden;
	cursor: pointer;
	opacity: 0;

	transition: $transition;
	transition-property: opacity;
}
