@import 'shared/styles/global';

$header-height: 120px;

.info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 2, 2, 3);

	&Buttons {
		display: flex;
		gap: spacing(1);
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 10;

	background-color: white;
}

.list {
	padding-bottom: spacing(2);

	&Control {
		display: flex;
		justify-content: space-between;
		margin-bottom: spacing(2);
		padding: spacing(0, 2);
	}
}

.project {
	padding: spacing(1,1,1,3);
}
