@import 'shared/styles/global';

$c-button-disabled: #999;

.container {
	display: flex;

	height: 100vh;

	background-size: contain;
}

.login {
	margin-top: 44px;
	margin-bottom: 44px;

	padding-top: 24px;
	padding-bottom: 38px;

	display: flex;
	flex-direction: column;

	align-items: center;

	justify-content: space-between;

	max-height: 700px;

	width: 100%;
	text-align: center;
}

.wrapper {
    display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: spacing(10);

	width: 400px;

	text-align: center;
}

.button {
    position: relative;

    display: flex;

    gap: spacing(2);

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 48px;

    border-radius: 8px;

    color: white;

    font-family: $f-inter;

    text-decoration: none;

    background: black;

    margin-bottom: spacing(4);

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }

    &:hover {
        background: #1E78FF;
    }
}

.body {
    display: flex;
    flex-direction: column;
    gap: spacing(1.5);
    align-items: center;
    margin-bottom: spacing(7.5);
}
