@import "shared/styles/global";

.wrapper {
	max-width: 878px;
	margin: 40px auto;
}

.title {
	margin-bottom: spacing(1);
}

.subtitle {
	margin-bottom: spacing(4);
}

.list {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
}
