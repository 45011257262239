@import "shared/styles/global";

.tabs {
	&Wrapper {
		position: relative;

		width: fit-content;
		margin: 0 auto;
	}

	&Save {
		position: absolute;
		top: -27.5px;
		left: -49px;
		z-index: 20;

		padding: 4px 8px;
		border-radius: 1px;

		background-color: #8774ff;
		transform: rotate(-30deg);
	}

	&Arrow {
		position: absolute;
		bottom: -3px;
		left: 50%;

		color: #8774ff;

		transform: translateX(-50%) rotate(180deg);
	}
}


.cards {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	justify-content: center;

	&Wrapper {
		display: flex;
		flex-direction: column;
		gap: spacing(5);
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: spacing(5);
	width: 100%;
	min-width: 305px;
	padding: spacing(4, 3);

	&Top {
		min-height: 70px;
	}

	&Description {
		min-height: 6em;
	}

	&Perks {
		display: flex;
		flex-direction: column;
	}

	&Gradient {
		border: solid 2px transparent;
		border-radius: 4px;

		&Before {
			margin: -2px;

			background: linear-gradient(
					to bottom right,
					rgba($c-primary-gradient-left, 0.4),
					rgba($c-primary-gradient-right, 0.4)
			);
		}
	}

	&Title {
		margin-bottom: spacing(1);

		text-transform: capitalize;
	}

	&Price {
		display: flex;
		gap: spacing(1);
		align-items: center;
		min-height: 38px;

		@include laptop {
			flex-direction: column;
			align-items: flex-start;
		}

		&Custom {
			@include laptop {
				margin-bottom: 22px;
			}
		}
	}

	ul {
		list-style: none;

		li {
			display: flex;
			gap: spacing(1);
			align-items: center;

			img {
				width: 20px;
				height: 20px;
			}

			&:not(:last-child) {
				margin-bottom: spacing(2);
			}

			span {
				font-weight: 700;
			}
		}
	}

	&Pro {
		background-color: $c-very-light-green;
	}
}

.blocked {
	color: $c-dark-grey;
}

.contact {
	text-align: center;

	a {
		color: black;
	}
}

.bottomButton {
	position: absolute;
	bottom: spacing(4);
	left: spacing(3);

	width: calc(100% - 48px);
}

.oneTime {
	display: flex;
	gap: spacing(8);
	justify-content: center;
	align-items: center;

	&Prices {
		display: flex;
		flex-direction: column;
		gap: spacing(2);

		min-width: 360px;
	}

	&Card {
		display: flex;
		flex-direction: column;
		gap: spacing(5);
		width: 100%;
		max-width: 400px;
		padding: spacing(4, 3, 3, 3);

		&Top {
			min-height: 70px;
	
			&Row {
				display: flex;
				justify-content: space-between;
			}
		}

		&Title {
			margin-bottom: spacing(1);
		}
	}

	&Description {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
	}
}
