@import "shared/styles/global";

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;

	&Icon {
		padding: 1px;
	}

	div, button {
		display: flex;
		gap: spacing(1);
		align-items: center;
		height: 22px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(1);
	padding: spacing(1, 0, 3, 2);
}

.icon {
	transition: $transition;
	transition-property: transform;

	&Hide {
		transform: rotate(180deg);
	}
}

.row {
	&:not(:last-child) {
		padding-bottom: spacing(2);
	}
}
