@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

a {
	font-family: $f-inter;
	text-decoration: none;
	color: inherit;
}

ul {
	list-style-type: none;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $f-inter;
	color: #000;
	font-size: 18px;
	font-weight: 400;
	max-width: 1080px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.faqSection {
	margin-bottom: 100px;
	width: 100%;
}

.faqTitle {
	font-size: 38px;
	margin-bottom: 40px;
	padding-left: 12px;
}

.priceSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 80px 0 100px;
}

.sectionTitle {
	font-size: 60px;
	font-weight: 700;
	letter-spacing: 0;
	text-align: center;
	margin-bottom: 30px;
}

.sectionText {
	font-size: 24px;
	font-weight: 400;
	color: #555;
}

.priceItems {
	display: flex;
	gap: 35px;
	margin-top: 90px;
}

.priceItem {
	width: 340px;
	display: flex;
	flex-direction: column;
	border: 1px solid #979797db;
	background-color: #fff;
	padding: 35px;
	border-radius: 8px;
}

.itemContent {
	margin: 20px 0 35px;
	display: flex;
	flex-direction: column;
}

.itemTitle {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 8px;
}

.itemText {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 30px;
}

.itemPrice {
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 4px;
}

.itemDescr {
	font-size: 14px;
	color: #636363;
	font-weight: 400;
	margin-bottom: 30px;
}

.itemLink {
	text-align: center;
	color: #fff;
	background-color: #000;
	border-radius: 8px;
	padding: 12px 16px;
	margin-bottom: 12px;
	position: relative;
	transition: color 0.2s ease, background-color 0.2s ease;
	cursor: pointer;
}

.itemLink:hover {
	color: #222;
	background-color: #0097dd;
	background-image: linear-gradient(135deg, #0097dd, #00d85e);
}

.linkDescr {
	align-self: center;
	font-size: 14px;
	color: #636363;
	font-weight: 400;
}

.featuresTitle {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 15px;
}

.listItem {
	display: flex;
	gap: 5px;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 12px;
}

@media (max-width: 1200px) {
	.priceItems {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 45px;
		justify-items: center;
	}

	.priceItem:nth-child(3) {
		grid-column: span 2;
	}
}

@media (max-width: 768px) {
	.priceSection {
		margin-top: 50px;
		padding: 0 8px;
	}

	.sectionTitle {
		font-size: 44px;
	}

	.priceItems {
		display: flex;
		flex-direction: column;
		margin-top: 70px;
	}
}
@media (max-width: 560px) {
	.priceSection {
		margin: 50px 0 60px;
	}

	.sectionTitle {
		font-size: 36px;
	}

	.priceItems {
		margin-top: 50px;
		gap: 35px;
	}

	.faqTitle {
		font-size: 32px;
	}

	.faqSection {
		padding: 0 8px;
		margin-bottom: 50px;
	}
}

@media (max-width: 445px) {
	.sectionTitle {
		font-size: 30px;
	}

	.sectionText {
		text-align: center;
		font-size: 18px;
	}

	.priceSection{
		margin: 35px 0 50px;
		
	}
}

@media (max-width: 400px) {
	.sectionTitle {
		font-size: 28px;
	}
}
