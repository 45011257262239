@import 'shared/styles/global';

*,
*::after,
*::before {
	box-sizing: inherit;
}

a {
	font-family: $f-inter;
	text-decoration: none;
	color: inherit;
}

ul {
	list-style-type: none;
}

button {
	border: none;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	font-family: $f-inter;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $f-inter;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	max-width: 1080px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.absoluteBlock {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.landingGradient {
	height: 430px;
	bottom: auto;
	z-index: -1;
}

.colorGradient {
	background: linear-gradient(95deg, #d554e999 0%, #5499e900 100%, #5499e900 100%);
	z-index: -1;
}

.blockTitle {
	font-size: 38px;
	font-weight: 700;
	margin-bottom: 45px;
	line-height: 40px;
}

///////    TOP CONTENT   /////////

.topContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $f-inter;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	max-width: 1080px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 auto;
}

.topContent {
	display: flex;
	max-width: 820px;
	height: 354px;
	width: 100%;
	align-items: start;
}

.topTitle {
	display: flex;
	max-width: 630px;
	font-size: 54px;
	line-height: normal;
	font-weight: 700;
	margin-top: 65px;
}

.topImg {
	width: 500px;
	position: absolute;
	right: 0;
	top: 115px;
}

///////    CONTENT DESCR   /////////

.contentDescr {
	max-width: 820px;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
}

///////    CONTENT EXTENDED   /////////

.contentMeet {
	max-width: 820px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin-top: 20px;
}

.blockWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 45px;
	justify-content: space-between;
}

.startTitle {
	font-size: 38px;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 20px;
}

.endTitle {
	font-size: 38px;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 60px;
}

.startText {
	max-width: 440px;
	width: 100%;
}

.endWrapper {
	margin-top: 40px;
}

.endText {
	display: flex;
	max-width: 440px;
	width: 100%;
}

.contentImg1 {
	max-width: 280px;
	padding-right: 20px;
}

.contentImg2 {
	max-width: 320px;
}

///////    CONTENT PURPOSE   /////////

.contentPurpose {
	max-width: 820px;
	width: 100%;
	margin-top: 70px;
	display: flex;
	flex-direction: column;
	justify-content: start;
}

///////    CONTENT GRID   /////////

.contentGrid {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 820px;
	width: 100%;
}

.gridItems {
	display: grid;
	grid-template-columns: repeat(2, 380px);
	justify-content: space-between;
	gap: 60px;
	margin: 50px 0;
}

.gridItem {
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 25px;
}

.gridInner {
	display: flex;
	gap: 25px;
}

.gridImg {
	width: 48px;
}

.itemTitle {
	align-self: center;
	font-size: 22px;
	font-weight: 700;
}

.contentLink {
	display: flex;
	align-self: center;
	color: #222;
	height: 100%;
	font-size: 26px;
	font-weight: 700;
	align-items: center;
	justify-content: center;
	padding: 18px 24px;
	margin: 60px 0 120px;
	border-radius: 14px;
	background: linear-gradient(92deg, rgba(213, 84, 233, 0.8) 0.89%, rgba(84, 153, 233, 0.8) 96.97%);
	z-index: 0;
	position: relative;
	transition: color 0.2s ease;
}

.contentLink::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 14px;
	background: linear-gradient(92deg, rgba(67, 152, 211, 0.8) 0.89%, rgba(96, 209, 116, 0.8) 96.97%);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.contentLink:hover {
	color: #fbf9f7;
	background-color: #d4b0f4;
}

.contentLink:hover::before {
	opacity: 1;
}

@media (max-width: 1450px) {
	.topImg {
		width: 450px;
		top: 115px;
	}

	.topTitle {
		font-size: 48px;
	}

	.blockWrapper {
		gap: 35px;
	}

	.contentBanner3 {
		padding-right: 20px;
	}
}

@media (max-width: 1180px) {
	.topContent {
		justify-content: center;
	}

	.topTitle {
		text-align: center;
	}

	.topImg {
		display: none;
		text-align: center;
	}
}

@media (max-width: 1050px) {
	.landingGradient {
		height: 380px;
	}

	.topContent {
		height: 300px;
	}

	.startTitle, 
	.endTitle,
	.blockTitle{
		margin-bottom: 30px;
	}

	.topTitle {
		margin-top: 50px;
		text-align: center;
	}

	.blockWrapper {
		flex-direction: column;
	}

	.endWrapper {
		order: 1;
	}

	.contentImg2 {
		order: 2;
	}

	.endText,
	.startText {
		margin: 0;
		max-width: 100%;
	}

	.contentImg1 {
		padding-right: 0;
	}
}

@media (max-width: 900px) {
	.container {
		max-width: 700px;
		padding: 0 60px;
	}

	.gridItems {
		justify-content: center;
		grid-template-columns: repeat(1, auto);
	}

	.gridItem {
		flex-direction: column;
	}
}

@media (max-width: 650px) {
	.topContent {
		height: 240px;
	}

	.landingGradient {
		height: 310px;
	}

	.topTitle {
		font-size: 36px;
		margin-top: 35px;
		padding: 0 30px;
	}

	.startTitle,
	.endTitle,
	.blockTitle {
		font-size: 28px;
	}
}

@media (max-width: 480px) {
	.container {
		padding: 0 20px;
	}

	.landingGradient {
		height: 250px;
	}

	.topContent {
		height: 180px;
	}

	.topTitle {
		font-size: 26px;
		padding: 0 10px;
	}

	.contentDescr{
		margin-top: 30px;
	}

	.startTitle,
	.endTitle,
	.blockTitle {
		font-size: 24px;
		margin-bottom: 20px;
	}

	.gridItems {
		gap: 35px;
	}

	.contentBanner2 {
		max-width: 370px;
	}

	.contentBanner3 {
		max-width: 350px;
	}

	.contentPurpose {
		margin-top: 30px;
	}

	.gridInner {
		align-self: flex-start;
		gap: 15px;
	}

	.itemTitle {
		font-size: 20px;
	}
}

@media (max-width: 400px) {
	.topTitle{
		margin-top: 20px;
	}

	.landingGradient {
		height: 230px;
	}

	.topContent {
		height: 170px;
	}

	.contentBanner2 {
		max-width: 350px;
	}

	.contentBanner3 {
		max-width: 330px;
	}
}
