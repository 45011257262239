@import 'shared/styles/global';

$c-button-disabled: #999;

.button {
    position: relative;

    height: 46px;
    width: 100%;

    margin-bottom: 20px;
    padding: 13px 89px 13px 94px;

    border: 1px solid black;
    border-radius: 10px;

    font-family: $f-inter;

    text-decoration: none;

    &Icon {
        position: absolute;
        top: 13px;
        left: 24px;
    }

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }
}
