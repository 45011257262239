@import 'shared/styles/global';

.title {
	height: 44px;
	margin-bottom: 1px;
	padding: 11px 0;
}

.bio {
	padding: spacing(1, 0, 0, 2);
}
