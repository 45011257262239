@import 'shared/styles/global';

.height {
	height: calc(100vh - 174px);
	overflow-y: scroll;
}

.form {
	flex: 1;
	align-self: center;

	&Section {
		flex: 1;
		padding: spacing(2, 4, 1, 4);

		:global(.ant-checkbox-wrapper) {
			padding-left: spacing(2);
		}

		:global(.ant-radio-wrapper) {
			padding-left: spacing(2);
		}

		:global(.ant-form-item) {
			margin-bottom: 0;
		}

		:global(.ant-form-item-no-colon) {
			font-size: 15px !important;
			font-weight: 500;
			font-family: Inter, sans-serif;
			line-height: 120%;
		}
	}

	&Button {
		width: 226px;

		@include tablet {
			width: auto;
		}
	}

	&Buttons {
		position: sticky;
		bottom: 0;

		display: flex;
		justify-content: space-between;
		padding: spacing(2);
		border-top: 1px solid $c-grey;

		background: white;
	}

	&Last {
		margin-bottom: 120px;
	}
}

.buttonContainer {
	margin: 0;
}

.parsed {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: spacing(0.5);
	padding: spacing(2, 4);

	background-color: $c-light-purple;
}

.rockstar {
	margin-top: spacing(2);

	&Label {
		margin-bottom: spacing(1);
	}
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}

	&Tooltip {
		z-index: 10002;

		max-width: 320px;

		&Advanced {
			max-width: 600px;
		}
	}
}

.tooltip {
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.section {
	margin-bottom: 16px;
}

.sectionHeader {
	padding: spacing(3, 4, 1, 4);
	border-top: 1px solid $c-lighter-grey;

	text-transform: uppercase;


	&First {
		padding: spacing(2, 4, 1, 4);
		border-top: none;
	}
}

.row {
	display: flex;
	width: 100%;

	&Funding {
		gap: 16px;
	}
}

.funding {
	display: flex;
	gap: spacing(3);
	align-items: start;

	&:hover {
		.clear {
			visibility: visible;
			opacity: 1;
		}
	}
}

.clear {
	margin-top: spacing(0.25);

	visibility: hidden;
	opacity: 0;

	transition: $transition;
	transition-property: opacity;
}

.advanced {
	display: flex;
	align-items: center;

	cursor: pointer;

	&Icon {
		transform: rotate(-90deg);;
	}
}

.attach {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: spacing(1);
}

.slider {
	position: relative;
	padding-left: 6px;
	margin-top: spacing(4);
}
